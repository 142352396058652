import React, { ReactElement, ReactNode } from "react";

import { useDomainContext } from "@hooks/useDomainContext";

export default function CarWarrantyHeader({
    children,
    backGroundColor,
    boxShadow,
    border,
}: {
    children?: ReactNode;
    backGroundColor?: string;
    boxShadow?: string;
    border?: string;
}): ReactElement {
    const { domain } = useDomainContext();

    return (
        <header
            style={{
                backgroundColor: backGroundColor ? backGroundColor : "white",
                boxShadow: boxShadow ? boxShadow : "none",
                borderBottom: border ? border : "none",
            }}
        >
            <div className="inner py-5 lg:py-4 px-5 flex lg:max-w-7xl mx-auto justify-center lg:px-6 items-center">
                <div
                    className="logo max-w-250px"
                    style={{
                        backgroundImage: `url(${
                            domain?.logo?.fullPath as string
                        })`,
                        width: "auto",
                        height: "auto",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                    }}
                >
                    <a href="/" aria-label={`${domain.name} logo`}>
                        {domain?.logo?.fullPath ? (
                            <img
                                src={domain.logo.fullPath}
                                className="h-auto w-full"
                                alt={`${domain.name} logo`}
                                style={{ visibility: "hidden" }}
                            />
                        ) : (
                            <span className="children:w-full children:h-auto lg:children:h-10">
                                {children}
                            </span>
                        )}
                    </a>
                </div>
            </div>
        </header>
    );
}
